import { Component, Input } from '@angular/core';
import { ViewportScroller } from "@angular/common";

declare var FlipDown: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent {
  constructor(
    private scroller: ViewportScroller,
    ) {}


  ticketSale = new Date("2024-11-23T10:00:00")
  ticketSaleTime = this.ticketSale.getTime() / 1000;
  showTicketSale = false;


  // ! TICKET COUNTDOWN
  ngAfterViewInit(): void {
    new Date().getTime
    new FlipDown(this.ticketSaleTime, 
      {theme: "darkSelfMade",}).start();
  }


  goToAftermovie() {
    this.scroller.scrollToAnchor("aftermovie");
  }


  // ! TICKETS PROGRESS BAR
  @Input() progressFriday: number = 0; // Default progress set to 0 initially
  @Input() progressSaturday: number = 0; // Default progress set to 0 initially


  ngOnInit() {
    // Simulate progress animation on component initialization
    this.animateProgressBar();
    this.showTicketSale = new Date() >= this.ticketSale;
  }

  redirectToTickets(): void {
    window.open('https://tickets.parsidance.be/', '_blank');
  }

  animateProgressBar() {
    // Set progress to 80% over a specific duration (e.g., 2 seconds)
    setTimeout(() => {
      this.progressFriday = 80;
    }, 1000); // Change the time duration as needed
    setTimeout(() => {
      this.progressSaturday = 100;
    }, 1000);
  }
}
